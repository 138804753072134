<template>
  <v-card elevation="4" class="mt-3 ml-2">
    <WorkButtons />
    <!-- <base-overlay  :opacity="0.3" :zIndex="2" :progressSize="80" :progressWidth="7"></base-overlay>     -->
    <v-overlay
      absolute
      color="secondaryLighten"
      :opacity="opacity"
      :z-index="zIndex"
      :value="isLoading"
    >
      <v-progress-circular
        size="80"
        width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <WorkHeader />
    <v-container id="container">
      <v-tabs v-model="tab">
        <v-tab
          v-for="(item, i) in items"
          :key="i"
          :id="'tab-' + item.id"
          :disabled="item.disabled"
        >
          {{ $t(item.title) }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" touchless>
        <v-tab-item>
          <WorkText />
        </v-tab-item>
        <v-tab-item>
          <Metadata />
        </v-tab-item>
        <v-tab-item>
          <Visualizations />
        </v-tab-item>
      </v-tabs-items>
    </v-container>
    <v-dialog id="footnoteDialog" v-model="footnoteDialog" width="500">
      <v-card class="show-mention">
        <v-card-title class="primary white--text">
          Nota {{ footnoteNumber }}
          <v-spacer> </v-spacer>
          <v-btn fab x-small @click="toggleFootnoteDialog()">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-2" v-html="footnoteBody"> </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-0">
          <v-spacer></v-spacer>
          <v-card-subtitle>
            <i>Autore/Autrice della nota:</i> {{ footnoteCreator }}
          </v-card-subtitle>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog id="entityDialog" v-model="entityDialog" width="600">
      <v-card>
        <v-overlay
          absolute
          color="secondaryLighten"
          opacity="0.5"
          :z-index="zIndex"
          :value="entityIsLoading"
        >
          <v-progress-circular
            size="50"
            width="3"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-overlay>
        <v-card-title class="primary white--text">
          <v-icon color="white" class="mr-1">{{ entityDialogIcon }}</v-icon>
          {{ entityDialogTitle }}
          <v-spacer> </v-spacer>
          <v-btn fab x-small @click="toggleEntityDialog()">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-container class="d-flex">
          <!-- <v-img
            :lazy-src="entityDialogHTML.img"
            max-height="150"
            max-width="250"
            :src="entityDialogHTML.img"
          ></v-img> -->
          <v-card-text class="pt-0" v-html="entityDialogBody"> </v-card-text>
        </v-container>
        <!-- <v-card-text v-if="entityDialogSource" class="text-right">
          Fonte: {{ entityDialogSource }}
        </v-card-text> -->
        <v-divider></v-divider>
        <v-card-actions
          v-if="entityDialogLinks && entityDialogLinks.length > 0"
        >
          <template v-for="link in entityDialogLinks">
            <v-tooltip bottom :key="link.name">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :href="link.href"
                  color="primary"
                  target="_blank"
                  text
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <img class="mr-1" src="@/assets/treccaniLogo.svg" />
                  {{ link.name }}
                </v-btn>
              </template>
              <span>{{ link.tooltip }}</span>
            </v-tooltip>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import WorkButtons from "./header/WorkButtons";
import WorkHeader from "./header/WorkHeader";
import WorkText from "./text/Text";
import Metadata from "./metadata/Metadata";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  components: {
    WorkButtons,
    WorkHeader,
    WorkText,
    Metadata,
  },
  data() {
    return {
      prevWikidataID: "",
      isLoading: false,
      entityIsLoading: false,
      opacity: 0.2,
      zIndex: 2,
      footnoteDialog: false,
      entityDialog: false,
      dialogHtml: {
        footnoteNumber: "",
        footnoteBody: "",
        footnoteCreator: "",
      },
      entityDialogHTML: {
        icon: "",
        title: "",
        body: "",
        img: null,
        source: null,
        links: [],
      },
      tab: null,
      treccani: [
        {
          id: "P6404",
          name: "Storico",
          tooltip: "Dizionario di Storia Treccani",
          href: `_(Dizionario-di-Storia)`,
        },
        {
          id: "P7993",
          name: "Filosofico",
          tooltip: "DIzionario di Filosofia Treccani",
          href: `_(Dizionario-di-filosofia)`,
        },
        {
          id: "P3365",
          name: "Enciclopedia",
          tooltip: "Enciclopedia Treccani",
          href: ``,
        },
      ],
      items: [
        {
          id: "text",
          title: "collection.work.tabs.text.title",
          disabled: false,
        },
        {
          id: "metadata",
          title: "collection.work.tabs.metadata.title",
          disabled: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      categories: "work/getCategories",
      footnotes: "work/getFootnotes",
    }),
    footnoteNumber() {
      return this.dialogHtml.footnoteNumber;
    },
    footnoteBody() {
      return this.dialogHtml.footnoteBody;
    },
    footnoteCreator() {
      return this.dialogHtml.footnoteCreator;
    },
    entityDialogIcon() {
      return this.entityDialogHTML.icon;
    },
    entityDialogTitle() {
      return this.entityDialogHTML.title;
    },
    entityDialogBody() {
      return this.entityDialogHTML.body;
    },
    entityDialogSource() {
      return this.entityDialogHTML.source;
    },
    entityDialogLinks() {
      return this.entityDialogHTML.links;
    },
  },
  methods: {
    updateFootnoteDialog(event) {
      const footnoteId = event.target.id.replace(/-ref/g, "");

      const footnote = this.footnotes.filter((fn) => fn.id === footnoteId)[0];
      this.dialogHtml = {
        footnoteNumber: footnote.id.replace(/\D+/g, ""),
        footnoteBody: footnote.body,
        footnoteCreator: footnote.creator,
      };

      this.toggleFootnoteDialog();
    },
    async updateEntityDialog(event) {
      this.entityIsLoading = true;
      this.toggleEntityDialog();

      const resource = event.target.getAttribute("resource");
      let wikidataURI = "",
        links = [],
        entCategory = "",
        found = false,
        label;

      for (let cat in this.categories) {
        for (let entity of this.categories[cat]) {
          if (entity.id === resource && !found) {
            label = entity.label;
            entCategory = cat;
            if (entity.wikidataURI !== "") {
              wikidataURI = entity.wikidataURI;
              if (entity.treccaniID !== "") {
                links.push({
                  name: "Biografico",
                  tooltip: "Dizionario Biografico degli italiani Treccani",
                  href: `https://www.treccani.it/enciclopedia/${entity.treccaniID}_(Dizionario-Biografico)`,
                });
              }
            }
            found = true;
            break;
          }
        }
        if (found) break;
      }

      const icon = this.$store.getters["work/getCategoriesStructure"].filter(
        (cat) => cat.id === entCategory
      )[0].icon;
      if (wikidataURI === "") {
        this.prevWikidataID = "";
        this.constructEntityDialog(icon, label, null, []);
      } else {
        let split = wikidataURI.split("/");
        const wikidataID = split[split.length - 1];

        if (wikidataID !== this.prevWikidataID) {
          this.prevWikidataID = wikidataID;
          const response = await this.queryWikidata(wikidataID, links);
          if (!response) {
            this.constructEntityDialog(
              "mdi-help",
              "Entità",
              "Entità non riconosciuta",
              null,
              []
            );
          } else {
            this.constructEntityDialog(icon, label, response.body, links);
          }
        }
      }
      this.entityIsLoading = false;
    },
    async queryWikidata(wikidataID, links) {
      const lang = this.$i18n.locale;

      const response = await axios.get(
        `https://www.wikidata.org/w/api.php?action=wbsearchentities&origin=*&format=json&language=${lang}&uselang=${lang}&search=${wikidataID}`
      );

      for (let t of this.treccani) {
        const entity = await axios.get(
          `https://www.wikidata.org/w/api.php?action=wbgetclaims&format=json&property=${t.id}&entity=${wikidataID}&origin=*`
        );

        if (entity.data.claims[t.id]) {
          const tValue =
            entity.data.claims[t.id][0].mainsnak.datavalue.value;
          links.push({
            name: t.name,
            tooltip: t.tooltip,
            href: `https://www.treccani.it/enciclopedia/${tValue}${t.href}`,
          });
        }
      }
      
      let description = "";

      if (response.data.search[0].description)
        description =
          response.data.search[0].description.charAt(0).toUpperCase() +
          response.data.search[0].description.slice(1);

      return {
        title: response.data.search[0].label,
        body: description,
      };
    },    
    constructEntityDialog(icon, title, body, links) {
      this.entityDialogHTML = {
        icon,
        title,
        body,
        links,
      };
    },
    toggleFootnoteDialog() {
      this.footnoteDialog = !this.footnoteDialog;
    },
    toggleEntityDialog() {
      this.entityDialog = !this.entityDialog;
    },
    addFootnoteClickEvent() {
      document.querySelectorAll('[id^="curatornote-ref-"],[id^="moronote-ref-"]').forEach((ref) => {
        ref.addEventListener("click", this.updateFootnoteDialog);
      });
    },
    addEntityClickEvent() {
      document.querySelectorAll(".mention").forEach((mention) => {
        mention.addEventListener("click", this.updateEntityDialog);
      });
    },
    async updateWork() {
      this.isLoading = true;
      await this.$store.dispatch("work/updateWork", this.$route.params.id);
      await this.$store.dispatch("work/formatCategoryPanel");

      this.isLoading = false;
    },
  },
  watch: {
    $route() {
      this.updateWork();
    },
  },
  async created() {
    await this.updateWork();
    this.addFootnoteClickEvent();
    this.addEntityClickEvent();
  },
  updated() {
    this.addFootnoteClickEvent();
    this.addEntityClickEvent();
  },
};
</script>

<style lang="scss">
</style>