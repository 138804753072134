<template>
  <v-speed-dial
    v-model="goBack"
    :top="top"
    :bottom="bottom"
    :right="right"
    :left="left"
    :transition="transition"
  >
    <template v-slot:activator>
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-model="goBack"
            color="primary"
            elevation="2"
            fab            
            :to="browsingState"
            v-bind="attrs"
            v-on="on"            
            ><v-icon>mdi-arrow-left-bold </v-icon></v-btn
          >
        </template>
        <span>Torna alla navigazione</span>
      </v-tooltip>
    </template>
  </v-speed-dial>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  data() {
    return {
      goBack: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",
    };
  },
  computed: {
    ...mapGetters(['browsingState'])
  }
};
</script>