<template>
<v-speed-dial
    v-model="fab"
    :top="top"
    :bottom="bottom"
    :right="right"
    :left="left"
    :direction="direction"
    :open-on-hover="hover"
  >
    <template v-slot:activator>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-model="fab"
            color="primary"
            elevation="2"
            fab
            v-bind="attrs"
            v-on="on"
          >
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-icon v-else>mdi-download</v-icon></v-btn
          >
        </template>
        <span>Scarica</span>
      </v-tooltip>
    </template>
    <v-btn
        class="download-format"
        v-for="f in formats"
        :key="f.format"
        fab
        small
        color="primary"
        @click="downloadFile(f.format)"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on"> {{ f.icon }} </v-icon>
          </template>
          <span>{{ f.title }}</span>
        </v-tooltip>
      </v-btn>    
  </v-speed-dial>  
</template>

<script>
import axios from 'axios';
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      fab: false,
      hover: false,
      direction: "left",
      top: false,
      right: false,
      bottom: true,
      left: true,
      transition: "",
      hashtags: "#enoam",
      formats: [
          {
              key: '0',
              format: 'pdf',
              title: 'Scarica PDF',
              icon: 'mdi-file-document-outline'
          },
          {
              key: '1',
              format: 'html',
              title: 'Scarica HTML',
              icon: 'mdi-language-html5'
          },
          {
              key: '0',
              format: 'xml',
              title: 'Scarica TEI',
              icon: 'mdi-xml mdiLanguageHtml5'
          }
      ]
    };
  },
  computed: {
    ...mapGetters({
      ident: "work/getIdent",
      title: "work/getTitle",
    }),
  }, 
  methods: {
    async downloadFile(format) {
      const response = await axios(
        "/api/download",
        // had to add this one here
        {
          responseType: "blob",
          params: {
            format: format,
            ident: this.ident.replace(/_/g, ""),
          },
        }
      );
      if (response.data) {
        console.log(response);
        let fileURL = window.URL.createObjectURL(response.data);
        //let fileURL = window.URL.createObjectURL(new Blob([response.data],{type:"application/pdf"}));
        //window.open(fileURL);
        let fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", `${this.title}.${format}`);
        document.body.appendChild(fileLink);

        fileLink.click();
      }
    },
  },
}
</script>