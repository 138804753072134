<template>
  <v-speed-dial
    v-model="fab"
    :top="top"
    :bottom="bottom"
    :right="right"
    :left="left"
    :direction="direction"
    :open-on-hover="hover"
  >
    <template v-slot:activator>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-model="fab"
            color="primary"
            elevation="2"
            fab
            v-bind="attrs"
            v-on="on"
          >
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-icon v-else>mdi-share-variant</v-icon></v-btn
          >
        </template>
        <span>Condividi</span>
      </v-tooltip>
    </template>
    <social-button
      v-for="social in socials"
      :key="social.id"
      :icon="social.icon"
      :title="social.title"
      :href="social.href"
      :dataHref="social.dataHref"
    >
    </social-button>
    <v-tooltip left>
    <template v-slot:activator="{ on, attrs }">
        <v-btn
        fab
        dark
        small
        color="primary"
        v-bind="attrs"
        v-on="on"
        @click="copyToClipboard"
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </template>
    <span> Copia URL </span>      
</v-tooltip>
  </v-speed-dial>  
</template>

<script>
import SocialButton from "./SocialButton";

export default {
  components: {
    SocialButton,
  },
  data() {
    return {
      fab: false,
      hover: false,
      direction: "bottom",
      top: false,
      right: false,
      bottom: true,
      left: true,
      transition: "",
      hashtags: "#enoam",
    };
  },
  computed: {
    postTitle() {
      const TITLE = "Consulta l'Edizione Nazionale delle Opere di Aldo Moro";
      return encodeURI(TITLE);
    },
    postURL() {
      const URL = this.$route.path;
      return encodeURI(`https://aldomorodigitale.unibo.it${URL}`);
    },
    socials() {
      const socials = [
        {
          id: 0,
          title: "Twitter",
          icon: "mdi-twitter",
          href: `https://twitter.com/share?url=${this.postURL}&text=${this.postTitle}&hashtags=${this.hashtags}`,
        },
        {
          id: 1,
          title: "Facebook",
          icon: "mdi-facebook",
          href: `https://www.facebook.com/sharer.php?u=${this.postURL}`,
        },
        {
          id: 2,
          title: "LinkedIn",
          icon: "mdi-linkedin",
          href: `https://www.linkedin.com/shareArticle?url=${this.postURL}&title=${this.postTitle}`,
        },
        {
          id: 3,
          title: "Whatsapp",
          icon: "mdi-whatsapp",
          href: `https://api.whatsapp.com/send?text=${this.postTitle} ${this.postURL}`,
        },
        {
          id: 4,
          title: "Email",
          icon: "mdi-email",
          href: `mailto:?subject=${this.postTitle}&body=Visita il link ${this.postURL}`,
        },
      ];
      return socials;
    },
  },
  methods: {
    copyToClipboard() {
      var inputc = document.body.appendChild(document.createElement("input"));
      inputc.value = window.location.href;
      
      inputc.select();
      document.execCommand("copy");
      inputc.parentNode.removeChild(inputc);
      this.$store.dispatch('setSnackbar',{text: 'URL copiato negli appunti.', color:'secondary'})  
    },
  },
};
</script>