<template>
  <div>
    <v-container class="d-flex justify-space-between align-center">
      <div>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
        <v-btn icon :to="checkLast(navigation.prev)" v-bind="attrs" v-on="on">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        </template>
        <span> {{ $t("collection.work.nav.prev") }} </span>
         </v-tooltip>
      </div>
      <v-card-title class="text-h5 font-weight-medium text-break text-center">
        {{ metadata.title }}
      </v-card-title>
      <div>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon :to="checkLast(navigation.succ)" v-bind="attrs" v-on="on">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </template>
          <span> {{ $t("collection.work.nav.next") }} </span>
        </v-tooltip>
      </div>
    </v-container>

    <v-divider class="mx-16"></v-divider>
    <v-container>
      <v-expansion-panels    
      style="z-index:0"
      class="mt-4"        
      multiple
      >
        <v-expansion-panel>
          <v-expansion-panel-header disable-icon-rotate
          class="text-overline"
          >
            {{ $t('collection.work.tabs.metadata.expressionMeta.abstract') }}
            <template v-slot:actions>
              <v-icon
              medium
              right
              >
                mdi-card-text-outline
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content
          class="text-body-2"
          >
            <em>{{ metadata.abstract }}</em>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header disable-icon-rotate
          class="text-overline"
          >
            {{ $t('collection.work.tabs.metadata.manifestationMeta.provenance') }}
            <template v-slot:actions>
              <v-icon
              medium
              right
              >
                mdi-check-decagram-outline
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content
          class="text-body-2"
          >
            <ul>
            <li v-for="source in metadata.provenance" :key="source">                
                  <em>{{ source }}</em>                
              </li>
            </ul>            
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header disable-icon-rotate
          class="text-overline"
          >
            {{ $t('collection.work.tabs.metadata.manifestationMeta.citation') }}
            <template v-slot:actions>
              <v-icon
              medium
              right
              >
                mdi-format-quote-close-outline
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content
          class="text-body-2"
          >
            <em>{{ metadata.citation }}</em>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>    
    </v-container>      
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      metadata: "work/getMetadata",
      navigation: "work/getNavigation"
    }),
  },    
  methods: {
      checkLast(nav) {
        return nav === '' ? this.$route.params.id : nav;
      }
    },  
};
</script>
