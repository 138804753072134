<template>
<v-card>
  <v-card>
    <v-card-title
    class="text-overline"
    >
      {{ $t(manifestationMeta.ident) }}
      <v-icon
        medium
        right
      >
        mdi-identifier
      </v-icon>
    </v-card-title>
    <v-card-subtitle>
      <a :href="'https://doi.org/' + metadata.doi">{{ metadata.doi }}</a>
    </v-card-subtitle>
  </v-card>
  <v-card>
    <v-card-title
    class="text-overline"
    >
      {{ $t(manifestationMeta.rights) }}
      <v-icon
        medium
        right
      >
        mdi-license
      </v-icon>
    </v-card-title>
    <v-card-subtitle>
    <a :href="metadata.rights">{{ metadata.rights }}</a>
    </v-card-subtitle>
  </v-card>
  <v-card>
    <v-card-title
    class="text-overline"
    >
      {{ $t(manifestationMeta.additionalNotes) }}
      <v-icon
        medium
        right
      >
        mdi-comment-text-outline
      </v-icon>
    </v-card-title>
    <v-card-subtitle v-if="metadata.additionalNotes">
      {{ metadata.additionalNotes }}
    </v-card-subtitle>
    <v-card-subtitle v-else>
      {{ $t('basics.undefined') }}
    </v-card-subtitle>
  </v-card>
</v-card>
</template>

<script>
export default {
    props: ['manifestationMeta','metadata','nameString']
}
</script>