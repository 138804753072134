<template>
  <v-container>
    <v-row>
      <v-col
        class="col-12 col-md-8"
        id="col-text"
        :order="smallDevices ? '2' : '1'"
      >
        <v-card-text
          id="work"
          class="text-justify"
          :class="readingMode ? '' : 'show-mention'"
          v-html="text"
        >
        </v-card-text>
      </v-col>
      <v-col
        id="col-panels"
        class="col-12 col-md-4 affix"
        align-self="start"
        :order="smallDevices ? '1' : '2'"
      >
        <CategoryPanel
          @selected-entities="toggleActiveMentions"
          @reading-mode="toggleReadingMode"
          :smallDevices="smallDevices"
        />
      </v-col>
    </v-row>
  </v-container>
</template> 

<script>
import CategoryPanel from "@/components/text/CategoryPanel";
import { mapGetters } from "vuex";

export default {
  components: {
    CategoryPanel,
  },
  props: ["work"],
  data() {
    return {
      readingMode: false,
    };
  },
  computed: {
    ...mapGetters({
      text: "work/getText",
      footnotes: "work/getFootnote",
    }),
    smallDevices() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    toggleReadingMode(readingMode) {
      this.readingMode = readingMode;
    },
    activateMention(id) {
      let mentions = document.querySelectorAll(`span[resource="${id}"]`);
      if (mentions.length > 0) {
        for (let mention of mentions) {
          mention.classList.add("active");
        }
      } else {
        let quotes = document.querySelectorAll(`span[about='${id}']`);
        if (quotes.length > 0) {
          for (let quote of quotes) {
            quote.classList.add("active");
          }
        }
      }
    },
    toggleActiveMentions({ newEntities, oldEntities }) {
      if (newEntities.length == oldEntities.length) {
        for (let newEntity of newEntities) {
          this.activateMention(newEntity.id);
        }
      }
      if (newEntities.length > oldEntities.length) {
        let addedEntities = newEntities.length - oldEntities.length;
        //let newEntity = newEntities[newEntities.length - 1]
        let i = 1;
        while (i <= addedEntities) {
          let newEntity = newEntities[newEntities.length - i];
          this.activateMention(newEntity.id);
          i++;
        }
      } else {
        //REMOVE
        //Work around to remove all elements at the same time
        let all = false;
        if (oldEntities.length - newEntities.length > 1) all = true;

        for (let i in oldEntities) {
          if (
            newEntities.length == 0 ||
            !newEntities.includes(oldEntities[i])
          ) {
            let removedEntity = oldEntities[i];
            let mentions = document.querySelectorAll(
              `span[resource="${removedEntity.id}"]`
            );

            if (mentions.length > 0) {
              for (let mention of mentions) {
                mention.classList.remove("active");
              }
              if (!all) return;
            } else {
              let quotes = document.querySelectorAll(
                `span[about='${removedEntity.id}']`
              );

              if (quotes.length > 0) {
                for (let quote of quotes) {
                  quote.classList.remove("active");
                }
                if (!all) return;
              }
            }
          }
        }
      }
    },
    all() {
      this.panel = [...Array(this.categories.length).keys()].map((k, i) => i);
    },
  },
};
</script>

<style lang="scss">
/* variables */
@import "@/styles/variables.scss";

/* curatornote */
#work {
  a {
    font-weight: bold;
    font-size: 110%;
    letter-spacing: 1px;
  }
  a[id^="moronote-ref"] {
    color: $moronote-color;
  }
  h2 {
    font-family: "Lato", sans-serif !important;
    padding: 1em;
    text-align: center;
    color: grey;
  }
}

/* paragraph */
.paragraph::before {
  content: attr(data-counter);
  position: absolute;
  display: inline-block;
  text-align: right;
  margin-left: -2em;
  min-width: 20px;
  display: inline-block;
  opacity: 0.5;
}

/* mention bibref quote */
.show-mention {
  .mention,
  .bibref,
  .quote {
    padding: 1px 2px 1px 2px;
    border-radius: 3px;
    border: 2px solid $default-color;
  }

  .mention:hover {
    cursor: pointer;
  }

  .mention::before,
  .bibref::before,
  .quote::before {
    font-family: "Material Icons";
    font-style: normal;
    display: inline-block;
    padding-right: 3px;
    vertical-align: top;
    color: $default-color;
  }

  .active,
  .active::before {
    color: white;
  }

  .person::before {
    content: "person";
  }

  .person:hover {
    border-color: $person-color;
  }
  .person:not(.active):hover::before {
    color: $person-color;
  }

  .person.active,
  .person.active::before {
    border-color: $person-color;
    background-color: $person-color;
  }

  .place::before {
    content: "place";
  }

  .place:hover {
    border-color: $place-color;
  }
  .place:not(.active):hover::before {
    color: $place-color;
  }

  .place.active,
  .place.active::before {
    border-color: $place-color;
    background-color: $place-color;
  }

  .organization::before {
    content: "business";
  }

  .organization:hover {
    border-color: $organization-color;
  }
  .organization:not(.active):hover::before {
    color: $organization-color;
  }

  .organization.active,
  .organization.active::before {
    border-color: $organization-color;
    background-color: $organization-color;
  }

  .bibref::before {
    content: "bookmark";
  }

  .bibref.active,
  .bibref.active::before {
    border-color: $bibref-color;
    background-color: $bibref-color;
  }

  .quote::before {
    content: "format_quote";
  }

  .quote.active,
  .quote.active::before {
    border-color: $quote-color;
    background-color: $quote-color;
  }
}
</style>
