<template>

        <v-btn
        fab
        dark
        small
        class="text--white"

        :color="color"
        :data-href="dataHref"
        :data-text="dataText"
        :data-hashtags="dataHashtags"
        :data-show-count="dataShowCount"
        target="_blank"
        :href="href"
      >
      <v-tooltip left>
    <template v-slot:activator="{ on, attrs }">
        <v-icon         v-bind="attrs"
        v-on="on" >{{ icon }}</v-icon>        
        </template>
        <span> {{ title }} </span>      
        </v-tooltip>
</v-btn>    

</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "primary",
    },
    title: {
        type: String,
    },
    icon: {
      type: String,
    },   
    dataHref: {
        type: String        
    },
    dataText: {
        type: String,
        default: "Dai uno sguardo all&#39;Edizione Nazionale delle Opere di Aldo Moro"
    },
    dataHashtags: {
        type: String,
        default: "enoam"
    },
    dataShowCount: {
        type: Boolean,
        default: false
    },
    href: {
        type: String
    }
  },
};
</script>