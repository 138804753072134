<template>
<v-card>
  <v-card>
    <v-card-title
    class="text-overline"
    >
      {{ $t(expressionMeta.contributor) }}
      <v-icon
        medium
        right
      >
        mdi-account-edit-outline
      </v-icon>
    </v-card-title>
    <v-card-subtitle>
      {{ metadata.curator }}
    </v-card-subtitle>
  </v-card>
  <v-card>
    <v-card-title
    class="text-overline"
    >
      {{ $t(expressionMeta.doctypeList) }}
      <v-icon
        medium
        right
      >
        mdi-file-document-multiple-outline
      </v-icon>
    </v-card-title>
    <v-card-subtitle>
      <span v-for="doctype in metadata.doctypeList" :key="doctype.uri">{{
            nameString("doctype", doctype)
          }}</span>
    </v-card-subtitle>
  </v-card>
  <v-card>
    <v-card-title
    class="text-overline"
    >
      {{ $t(expressionMeta.docstatus) }}
      <v-icon
        medium
        right
      >
        mdi-publish
      </v-icon>
    </v-card-title>
    <v-card-subtitle>
      {{ $t(`collection.advance_search.docstatus.list.${metadata.docstatus.id}`) }}
    </v-card-subtitle>
  </v-card>
  <v-card>
    <v-card-title
    class="text-overline"
    >
      {{ $t(expressionMeta.date) }}
      <v-icon
        medium
        right
      >
        mdi-calendar
      </v-icon>
    </v-card-title>
    <v-card-subtitle v-if="metadata.date">
      <span>
        {{ metadata.date }}
      </span>          
    </v-card-subtitle>
    <v-card-subtitle v-else>
      <span>
        {{ $t('basics.undefined') }} 
      </span>
    </v-card-subtitle>
  </v-card>
  <v-card>
    <v-card-title
    class="text-overline"
    >
      {{ $t(expressionMeta.eventPlace) }}
      <v-icon
        medium
        right
      >
        mdi-map-marker-outline
      </v-icon>
    </v-card-title>
    <v-card-subtitle v-if="metadata.eventPlace">
      <span>
        {{ metadata.eventPlace }}
      </span>          
    </v-card-subtitle>
    <v-card-subtitle v-else>
      <span>
        {{ $t('basics.undefined') }} 
      </span>
    </v-card-subtitle>
  </v-card>
</v-card>
</template>

<script>
export default {
    props: ['expressionMeta','metadata','nameString']
}
</script>