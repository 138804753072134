<template>
  <v-container>
    
    <v-row class="align-stretch">
      <v-col cols="12" sm="4">
        <v-card>          
            <WorkMeta :metadata="metadata" :nameString="nameString" :workMeta="workMeta" />
        </v-card>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card class="sheet">
          <ExpressionMeta :metadata="metadata" :nameString="nameString" :expressionMeta="expressionMeta" />
        </v-card>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card class="sheet">
          <ManifestationMeta :metadata="metadata" :nameString="nameString" :manifestationMeta="manifestationMeta" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import WorkMeta from '@/components/metadata/WorkMeta'
import ExpressionMeta from '@/components/metadata/ExpressionMeta'
import ManifestationMeta from '@/components/metadata/ManifestationMeta'

export default {  
  components: {
      WorkMeta,
      ExpressionMeta,
      ManifestationMeta
  },
  data() {
      return {
         workMeta: {
             doctopicList: 'collection.work.tabs.metadata.workMeta.doctopicList',
             roleList: 'collection.work.tabs.metadata.workMeta.roleList'
         },
         expressionMeta: {
             contributor: 'collection.work.tabs.metadata.expressionMeta.contributor',
             doctypeList: 'collection.work.tabs.metadata.expressionMeta.doctypeList',
             docstatus: 'collection.work.tabs.metadata.expressionMeta.docstatus',
             date: 'collection.work.tabs.metadata.expressionMeta.date',
             eventPlace: 'collection.work.tabs.metadata.expressionMeta.eventPlace'             
         },
         manifestationMeta: {
             ident: 'collection.work.tabs.metadata.manifestationMeta.ident',
             provenance: 'collection.work.tabs.metadata.manifestationMeta.provenance',
             //citation: 'collection.work.tabs.metadata.manifestationMeta.citation',
             rights: 'collection.work.tabs.metadata.manifestationMeta.rights',
             additionalNotes: 'collection.work.tabs.metadata.manifestationMeta.additionalNotes'
         }
      }
  },    
  computed: {
    metadata() {      
      return this.$store.getters["work/getMetadata"];
    },
  },
  methods: {
      nameString(metaType,metaValue){
          return this.$t(`collection.advance_search.${metaType}.list.${metaValue.id}`)
      }
  }
};
</script>

<style lang="scss" scoped>
  // .sheet {
  // overflow: auto;
  // white-space: nowrap;
  // }
</style>