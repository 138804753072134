<template>
  <div class="d-flex justify-space-between">
    <go-back-button></go-back-button>
    <div class="d-flex">
      <download-button class="mr-2"></download-button>
      <share-button></share-button>
    </div>    
  </div>
</template>

<script>
import GoBackButton from "@/components/buttons/GoBackButton.vue";
import ShareButton from "@/components/buttons/ShareButton";
import DownloadButton from "@/components/buttons/DownloadButton.vue";

export default {
  components: {
    GoBackButton,
    ShareButton,
    DownloadButton
  },
};
</script>

<style lang="scss" scoped>
.top {
  margin-top: 3px;
}

.left {
  margin-left: -8em;
}

.right {
  margin-right: -8em;
}
</style>