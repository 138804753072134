<template>
  <v-card>
    <v-card>
      <v-card-title
      class="text-overline"
      >
        {{ $t(workMeta.doctopicList) }}
        <v-icon
        medium
        right
      >
        mdi-bookshelf
      </v-icon>
      </v-card-title>
      <v-card-subtitle>
        <span class="mr-2"
            v-for="doctopic in metadata.doctopicList" :key="doctopic.uri">{{
              nameString("doctopic", doctopic)
            }}<br/></span>
      </v-card-subtitle>
    </v-card>
    <v-card>
      <v-card-title
      class="text-overline"
      >
        {{ $t(workMeta.roleList) }}
        <v-icon
        medium
        right
      >
        mdi-account-box-multiple-outline
      </v-icon>
      </v-card-title>
      <v-card-subtitle>
        <span class="mr-2"
            v-for="role in metadata.roleList" :key="role.uri">{{
              nameString("role", role)
            }}</span>
      </v-card-subtitle>
    </v-card>
  </v-card>  
</template>


<script>
export default {
    props: ['metadata','workMeta','nameString'],    
}
</script>