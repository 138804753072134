<template>
  <v-card id="text-options" v-scroll="toggleSticky">
    <v-toolbar dense color="primary" dark elevation="1" class="stickyToolbar">
      <v-toolbar-title class="font-weight-medium">
        <!-- <v-icon> mdi-book </v-icon> -->
        {{ $t("collection.work.tabs.text.indexes") }}
      </v-toolbar-title>
      <!-- <span>{{ $t("collection.work.tabs.text.readingMode") }}:</span> -->
      <v-spacer></v-spacer>
      <v-divider vertical class="mr-3" inset></v-divider>
      <div
        style="
          display: inline-flex;
          width: 20%;
          line-height: 1;
          margin-right: 0.6em;
        "
      >
        <span>
          {{ $t("collection.work.tabs.text.readingMode") }}
        </span>
      </div>
      <v-checkbox
        class="mt-5"
        dense
        v-model="readingMode"
        @change="$emit('reading-mode', readingMode)"
      >
      </v-checkbox>
    </v-toolbar>
    <v-expansion-panels id="categories" v-model="panel" focusable>
      <!-- CHAPTERS -->
      <v-expansion-panel v-if="chapters">
        <v-expansion-panel-header class="expansion-header">
          <div class="d-flex align-center">
            <v-icon class="mr-1">
              {{ chaptersStructure.icon }}
            </v-icon>
            {{ $t(chaptersStructure.title) }}
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="exp-content pr-0">
          <!--:items="cat.entities"-->
          <v-data-table         
            id="chapters-table" 
            class="data-table"
            :headers="chaptersStructure.headers"
            :items="chapters"
            :items-per-page="-1"
            item-key="id"
            :item-class="chaptersStructure.chapterClass"
            hide-default-header
            hide-default-footer
            mobile-breakpoint="0"
            dense
            @click:row="goToChapter"
          >
            <template v-slot:[`header.number`]="{header}">  
              {{ $t(header.text) }}
            </template>
            <template v-slot:[`header.label`]="{ header }">
              {{ $t(header.text) }}
            </template>
            <template v-slot:[`item.number`]="{ item }">
              <div class="number">
                {{ item.id.replace(/chapter-/,'') }}
              </div>
            </template>
            <template v-slot:[`item.label`]="{ item }">
              <div class="name my-1">
                {{ item.label }}
              </div>
            </template>            
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- CATEGORIES -->
      <v-expansion-panel v-for="cat in categories" :key="cat.id">
        <v-expansion-panel-header class="expansion-header">
          <div class="d-flex align-center">
            <v-icon class="mr-1" :color="cat.color">
              {{ cat.icon }}
            </v-icon>
            {{ $t(cat.title) }}
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="exp-content pr-0">
          <v-data-table
            v-model="selectedEntities"
            show-select
            class="data-table"
            :headers="headers"
            :items="cat.entities"
            :items-per-page="-1"
            item-key="id"
            hide-default-footer
            mobile-breakpoint="0"
            dense
          >
            <template v-slot:[`header.label`]="{}">
              {{ $t(headerLabel(cat.id)) }}
            </template>
            <template v-slot:[`header.occurrence`]="{ header }">
              {{ $t(header.text) }}
            </template>
            <template v-slot:[`header.data-table-select`]="{ on, props }">
              <v-simple-checkbox
                color="primary"
                v-bind="props"
                v-on="on"
                :ripple="false"
              ></v-simple-checkbox>
            </template>
            <template
              v-slot:[`item.data-table-select`]="{ isSelected, select }"
            >
              <v-simple-checkbox
                :color="cat.color"
                :value="isSelected"
                @input="select($event)"
                :ripple="false"
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.label`]="{ item }">
              <div class="name">
                {{ item[cat.show] }}
              </div>
            </template>
            <template v-slot:[`item.occurrence`]="{ item }">
              <v-chip small color="primary" dark>
                {{ item.occurrence }}
              </v-chip>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
//import axios from "axios";

export default {
  props: ["title", "smallDevices"],
  data() {
    return {
      headers: [
        {
          align: "start",
          cellClass: "content-name",
          value: "label",
        },
        {
          text: "collection.work.tabs.text.panel.occurrences",
          align: "end",
          class: "margin",
          value: "occurrence",
        },
      ],      
      chaptersStructure: {
        title: "collection.work.tabs.text.chapters",
        icon: "mdi-bookmark",
        chapterClass: () => 'chapterRow',
        headers: [
          {
            align: "end",
            cellClass: "content-name",
            value: "number",
          },
          {
            align: "start",
            text: "collection.work.tabs.text.chapter",
            cellClass: "content-name",
            value: "label",
          },
        ],
      },
      selectedEntities: [],
      panel: null,
      readingMode: false,
    };
  },
  computed: {
    categories() {
      return this.$store.getters["work/getCategoriesStructure"].filter(
        (cat) => cat.entities.length > 0
      );
    },
    chapters() {
      return this.$store.getters["work/getChapters"];
    },
  },
  methods: {
    openPanel(key) {
      this.panel = parseInt(key);
    },
    headerLabel(category) {
      console.log("CATEGORY", category);
      if (category === "expressions" || category === "quotes") {
        return "collection.work.tabs.text.panel.content";
      }
      return "collection.work.tabs.text.panel.name";
    },
    toggleSticky() {
      if (!this.smallDevices) {
        let topText = document
          .getElementById("col-text")
          .getBoundingClientRect().top;
        let textTab = document.getElementById("tab-text");

        if (textTab.classList.contains("v-tab--active")) {
          if (
            topText <= 124 &&
            !document
              .getElementById("text-options")
              .classList.contains("sticky")
          ) {
            document.getElementById("text-options").classList.add("sticky");
            console.log("add sticky");
          } else {
            if (
              topText >= 124 &&
              document
                .getElementById("text-options")
                .classList.contains("sticky")
            ) {
              console.log("remove sticky");
              document
                .getElementById("text-options")
                .classList.remove("sticky");
            }
          }
        }
      }
    },
    setPanelSize() {
      let widthPanel = document.getElementById("text-options").offsetWidth;

      document.getElementById("text-options").style.width = widthPanel + "px";
    },
    all() {
      this.panel = [...Array(this.categories.length).keys()].map((k, i) => i);
    },
    closeAllPanels() {
      this.panel = [];
    },
    async formatCategoryPanel() {
      await this.$store.dispatch("work/formatCategoryPanel");
    },
    goToChapter(item){
      this.$vuetify.goTo('#'+item.id)
    }
  },
  watch: {
    categories() {
      if (this.$route.query.entity) {
        for (let key in this.categories) {
          let selectedIndex = this.categories[key].entities.find((entity) =>
            entity.id.match(this.$route.query.entity)
          );
          if (selectedIndex) {
            this.openPanel(key);
            this.selectedEntities.push(selectedIndex);
          }
        }
      }
    },
    selectedEntities(newEntities, oldEntities) {
      this.$emit("selected-entities", { newEntities, oldEntities });
    },
    $route() {
      this.closeAllPanels();
    },
    panel(val) {
      console.log(val);
    },
  },
  created() {
    //this.formatCategoryPanel();
  },
  mounted() {
    // Right panel handling size and position
    this.setPanelSize();

    window.addEventListener("resize", this.setPanelSize);
    //window.addEventListener("scroll", this.toggleSticky);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setPanelSize);
    // window.removeEventListener("scroll", this.toggleSticky);
  },
};
</script>

<style lang="scss">
/* variables */
@import "@/styles/variables.scss";

.chapterRow:hover {
  cursor: pointer;
}

#categories {
  //max-width: 25em;
}
#categories .v-expansion-panel-content__wrap {
  padding: 0;
}
.expansion-header {
  padding: 16px 15px !important;
}
.sticky {
  position: fixed;
  top: 124px;
}

#chapters-table {
  max-height: 200px;
}

.data-table {
  max-height: 150px;
  overflow-y: scroll;
}

.name {
  max-height: 60px;
  scrollbar-width: thin;
  overflow: auto;
}

.margin {
  margin-right: 2em;
}
</style>  