var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.toggleSticky),expression:"toggleSticky"}],attrs:{"id":"text-options"}},[_c('v-toolbar',{staticClass:"stickyToolbar",attrs:{"dense":"","color":"primary","dark":"","elevation":"1"}},[_c('v-toolbar-title',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t("collection.work.tabs.text.indexes"))+" ")]),_c('v-spacer'),_c('v-divider',{staticClass:"mr-3",attrs:{"vertical":"","inset":""}}),_c('div',{staticStyle:{"display":"inline-flex","width":"20%","line-height":"1","margin-right":"0.6em"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("collection.work.tabs.text.readingMode"))+" ")])]),_c('v-checkbox',{staticClass:"mt-5",attrs:{"dense":""},on:{"change":function($event){return _vm.$emit('reading-mode', _vm.readingMode)}},model:{value:(_vm.readingMode),callback:function ($$v) {_vm.readingMode=$$v},expression:"readingMode"}})],1),_c('v-expansion-panels',{attrs:{"id":"categories","focusable":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[(_vm.chapters)?_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"expansion-header"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(_vm.chaptersStructure.icon)+" ")]),_vm._v(" "+_vm._s(_vm.$t(_vm.chaptersStructure.title))+" ")],1)]),_c('v-expansion-panel-content',{staticClass:"exp-content pr-0"},[_c('v-data-table',{staticClass:"data-table",attrs:{"id":"chapters-table","headers":_vm.chaptersStructure.headers,"items":_vm.chapters,"items-per-page":-1,"item-key":"id","item-class":_vm.chaptersStructure.chapterClass,"hide-default-header":"","hide-default-footer":"","mobile-breakpoint":"0","dense":""},on:{"click:row":_vm.goToChapter},scopedSlots:_vm._u([{key:"header.number",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.label",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"item.number",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"number"},[_vm._v(" "+_vm._s(item.id.replace(/chapter-/,''))+" ")])]}},{key:"item.label",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name my-1"},[_vm._v(" "+_vm._s(item.label)+" ")])]}}],null,true)})],1)],1):_vm._e(),_vm._l((_vm.categories),function(cat){return _c('v-expansion-panel',{key:cat.id},[_c('v-expansion-panel-header',{staticClass:"expansion-header"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":cat.color}},[_vm._v(" "+_vm._s(cat.icon)+" ")]),_vm._v(" "+_vm._s(_vm.$t(cat.title))+" ")],1)]),_c('v-expansion-panel-content',{staticClass:"exp-content pr-0"},[_c('v-data-table',{staticClass:"data-table",attrs:{"show-select":"","headers":_vm.headers,"items":cat.entities,"items-per-page":-1,"item-key":"id","hide-default-footer":"","mobile-breakpoint":"0","dense":""},scopedSlots:_vm._u([{key:"header.label",fn:function(ref){return [_vm._v(" "+_vm._s(_vm.$t(_vm.headerLabel(cat.id)))+" ")]}},{key:"header.occurrence",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.data-table-select",fn:function(ref){
var on = ref.on;
var props = ref.props;
return [_c('v-simple-checkbox',_vm._g(_vm._b({attrs:{"color":"primary","ripple":false}},'v-simple-checkbox',props,false),on))]}},{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"color":cat.color,"value":isSelected,"ripple":false},on:{"input":function($event){return select($event)}}})]}},{key:"item.label",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item[cat.show])+" ")])]}},{key:"item.occurrence",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":"primary","dark":""}},[_vm._v(" "+_vm._s(item.occurrence)+" ")])]}}],null,true),model:{value:(_vm.selectedEntities),callback:function ($$v) {_vm.selectedEntities=$$v},expression:"selectedEntities"}})],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }